<template>
    <div class="product-list-view">
        <div class="product-image">
            <img :src="product.image.url" alt="">
        </div>
        <div class="product-view-details">
            <p class="title" v-if="heading">{{ heading }}</p>
            <p class="title">{{ product.name }}</p>

            <div class="product-view-action">
                <div>
                    <p class="number">{{ $t('number') }}: 1</p>
                    <p class="price">{{ formatCurrency(minPrice) }}</p>
                </div>
                <div>
                    <div v-if="type === 'compare'">
                        <button class="product-compare" type="button" @click="addCompare">
                            {{ $t('view_comparison_list') }}
                        </button>
                    </div>
                    <router-link to="/order/" v-else>
                        <button :class="type === 'cart' ? 'bg-success' : ''" class="product-compare">
                            {{ $t('continue_ordering') }}
                        </button>
                    </router-link>
                    <router-link to="/" class="float-right"> <font-awesome-icon icon="fa-solid fa-angle-left" /> {{
                $t('continue_shopping') }}</router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        product: Object,
        type: String,
        heading: {
            type: String,
            default: ""
        },
    },
    data() {
        return {
            qty: 1,
            maxPrice: this.product.price_range.maximum_price.regular_price.value,
            minPrice: this.product.price_range.maximum_price.final_price.value,
        }
    },
    computed: {
        compareProducts() {
            return this.$store.getters["product/getProductCompare"]
        },
        isCompare() {
            return this.compareProducts.some((product) => product.sku == this.product.sku)
        },
    },
    methods: {
        formatCurrency(amount) {
            return this.$helpers.formatCurrency(amount);
        },
        changeQty: function (current) {
            this.qty = current;
        },
        addCompare: function () {
            this.$emit('close');
            if (this.isCompare) {
                const msg = {
                    type: "danger",
                    title: "Error",
                    text: "Product already added to compare list",
                };
                this.$store.dispatch("messages/sendMessage", { message: msg });
                return;
            }
            if (this.compareProducts.length >= 2) {
                const msg = {
                    type: "danger",
                    title: "Error",
                    text: "You can only compare 2 products",
                };
                this.$store.dispatch("messages/sendMessage", { message: msg });
                return;
            }
            this.$store.dispatch("product/addCompareProduct", { sku: this.product.sku })
            const msg = {
                type: "success",
                title: "Success",
                text: "Product added to compare list",
            };
            this.$store.dispatch("messages/sendMessage", { message: msg });
            // this.$router.push('/compare/')
        }
    }
}
</script>